var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"fixed-header":"","height":"calc(100vh - 200px)","hideDefaultHeader":"","headers":_vm.headers,"items":_vm.listItems,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"server-items-length":_vm.count,"item-class":_vm.getClassByRow,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.options=$event},"click:row":function (ref) {
	var id = ref.id;

	return _vm.onDetail(id);
},"update:page":function (page) { return _vm.onPagination({ page: page }); },"update:items-per-page":function (size) { return _vm.onPagination({ size: size }); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(ref){
var text = ref.text;
var colspan = ref.colspan;
return _c('th',{key:text,staticClass:"text-start",attrs:{"colspan":colspan}},[_vm._v(_vm._s(text))])}),0),_c('FilterRow',{attrs:{"items":_vm.headers},on:{"input":_vm.onPagination}})],1)]},proxy:true},{key:"item.inspection",fn:function(ref){
var inspection = ref.item.inspection;
return [_c('CompactCeil',{attrs:{"text":inspection}})]}},{key:"item.status.name",fn:function(ref){
var status = ref.item.status;
return [_c('CompactCeil',{attrs:{"text":status.name}})]}},{key:"item.company",fn:function(ref){
var company = ref.item.company;
return [_c('CompactCeil',{attrs:{"text":company}})]}},{key:"item.author",fn:function(ref){
var author = ref.item.author;
return [_c('CompactCeil',{attrs:{"text":author}})]}},{key:"item.workshopReviewer",fn:function(ref){
var workshopReviewer = ref.item.workshopReviewer;
return [_c('CompactCeil',{attrs:{"text":workshopReviewer}})]}},{key:"item.reviewer",fn:function(ref){
var reviewer = ref.item.reviewer;
return [_c('CompactCeil',{attrs:{"text":reviewer}})]}},{key:"item.inspector",fn:function(ref){
var inspector = ref.item.inspector;
return [_c('CompactCeil',{attrs:{"text":inspector}})]}},{key:"item.supply",fn:function(ref){
var supply = ref.item.supply;
return [_c('CompactCeil',{attrs:{"text":supply}})]}},{key:"item.workshop",fn:function(ref){
var workshop = ref.item.workshop;
return [_c('CompactList',{attrs:{"items":workshop}})]}},{key:"item.products",fn:function(ref){
var products = ref.item.products;
return [_c('CompactList',{attrs:{"items":products}})]}},{key:"item.type",fn:function(ref){
var type = ref.item.type;
return [_c('CompactList',{attrs:{"items":type}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }